@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';

/*
	Ethereal by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Page */

	body {
		overflow-x: scroll;
	}

/* Wrapper */

	#wrapper {
		body.is-preload & {
			@include vendor('transform', 'none');
			opacity: 1;
		}
	}