body {
  overflow-x: scroll;
}

body.is-preload #wrapper {
  opacity: 1;
  transform: none;
}

/*# sourceMappingURL=index.d0610a5e.css.map */
